<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    height="auto"
    permanent
  >
    <v-list-item class="px-2">

      <v-list-item-avatar>
        <v-icon class="txt-secondary">mdi-account</v-icon>
      </v-list-item-avatar>

      <v-list-item-title class="txt-secondary" style="font-weight: bold;">
        {{ userData.username }}
      </v-list-item-title>

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon class="txt-primary" >mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
      dense
    > 
      <v-list-item
        v-for="item in menuDashboard"
        :key="item.id"
        link
        :to="item.link"
        active-class="primary"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      drawer: true,
      mini: true,
      menu: [
        {
          id: 1,
          title: "Estadísticas Generales",
          icon: "mdi-chart-bar",
          link: "/dashboard/estadisticas-generales"
        },
        {
          id: 2,
          title: "Inventarios",
          icon: "mdi-format-list-numbered",
          link: "/dashboard/inventarios"
        },
      ],
      menuDistribuidor: [
        {
          id: 3,
          title: "Estatus Órdenes",
          icon: "mdi-format-list-checks",
          link: "/dashboard/estatus"
        },
        {
          id: 4,
          title: "Cancelaciones",
          icon: "mdi-file-cancel-outline",
          link: "/dashboard/cancelaciones"
        },
      ]
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    ...mapGetters('usuario', ['esDistribuidor']),
    menuDashboard() {
      this.esDistribuidor ? Array.prototype.push.apply(this.menu, this.menuDistribuidor) : ''
      return this.menu
    }
  }
}
</script>
